import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'InputBox',
  props: {
    rightIcon: {},
    leftIcon: {}
  },
  emits: ["change"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const posClass = props.rightIcon ? "right" : props.leftIcon ? "left" : "";

const onInput = (event: Event) => {
  emit("change", (event.target as HTMLInputElement).value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(posClass))
  }, [
    (props.leftIcon || props.rightIcon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(`fa-solid ${'fa-' + (props.leftIcon || props.rightIcon)}`)
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "_input",
      type: "text",
      onInput: onInput
    }, null, 32)
  ], 2))
}
}

})