import { Product } from "../types";

export type Component = {
  product: Product;
  name: string;
  endpoints: {
    url: string;
    usedQuota: number;
    quota: number;
  }[];
  rateLimit: number;
  rateLimitInternal: number;
};

export const getComponents = async (
  size = 10,
  page = 1
): Promise<{
  data: Component[];
  totalData: number;
}> => {
  const TOTAL_DATA = Math.floor(Math.random() * 100);
  const PRODUCTS: Product[] = ["talenta", "jurnal", "qontak", "klikpajak"];
  const data: Component[] = new Array(size).fill({}).map((_, i) => {
    const product = PRODUCTS[Math.floor(Math.random() * PRODUCTS.length)];
    const endpoints = new Array(Math.floor(Math.random() * 10) + 1)
      .fill({})
      .map((_, i) => {
        const quota = Math.round(Math.random() * 100000);
        const usedQuota = Math.round(Math.random() * quota);

        return {
          url: `/foo/${i}`,
          usedQuota,
          quota,
        };
      });

    return {
      product,
      name: `Foobar ${i}`,
      rateLimit: 100,
      rateLimitInternal: 60,
      endpoints,
    };
  });

  return {
    data,
    totalData: Math.max(TOTAL_DATA, data.length),
  };
};
