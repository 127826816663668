import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "_head" }

import InputBox from "../InputBox.vue";
import Modal from "../Modal.vue";
import ProgressBar from "../ProgressBar.vue";
import TableSection, { Events as TableEvents } from "../TableSection.vue";

export type Row = {
  url: string;
  usedQuota: number;
  quota: number;
};

export type Events = TableEvents & {
  search: [value: string];
  close: [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'EndpointListModal',
  props: {
    isModalOpen: { type: Boolean },
    data: {},
    totalData: {}
  },
  emits: ["change-size", "next", "prev", "page", "search", "close"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    header: "Endpoint URL",
    "is-modal-open": props.isModalOpen,
    width: "60vw",
    onClose: _cache[4] || (_cache[4] = ($event: any) => (emit('close')))
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(InputBox, {
          "left-icon": "magnifying-glass",
          onSearch: _cache[0] || (_cache[0] = (value) => emit('search', value))
        })
      ]),
      _createVNode(TableSection, {
        height: "60vh",
        "total-data": props.totalData,
        onChangeSize: _cache[1] || (_cache[1] = (value) => emit('change-size', value)),
        onNext: _cache[2] || (_cache[2] = (value) => emit('next', value)),
        onPrev: _cache[3] || (_cache[3] = (value) => emit('prev', value))
      }, {
        header: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("th", null, "Endpoint", -1),
          _createElementVNode("th", null, "Used", -1),
          _createElementVNode("th", null, "Used (%)", -1),
          _createElementVNode("th", null, "Quota", -1)
        ])),
        body: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data, (row, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, [
                _createElementVNode("p", null, _toDisplayString(row.url), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("p", null, _toDisplayString(row.usedQuota), 1)
              ]),
              _createElementVNode("td", null, [
                _createVNode(ProgressBar, {
                  progress: (row.usedQuota / row.quota) * 100
                }, null, 8, ["progress"])
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("p", null, _toDisplayString(row.quota), 1)
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["total-data"]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "_footer" }, [
        _createElementVNode("p", null, "Need guidance on your endpoint?"),
        _createElementVNode("a", { class: "_link" }, "Visit API Docs")
      ], -1))
    ]),
    _: 1
  }, 8, ["is-modal-open"]))
}
}

})