import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { Product } from "../api/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'Icon',
  props: {
    product: {},
    size: {}
  },
  setup(__props: any) {

const fileMap: Record<Product, string> = {
  talenta:
    "https://www.talenta.co/wp-content/uploads/2022/04/Mekari-Talenta-Logomark-RGB.png",
  jurnal:
    "https://www.jurnal.id/wp-content/uploads/2022/04/Mekari-Jurnal-Logomark-RGB.png",
  qontak:
    "https://qontak.com/wp-content/uploads/2024/06/logo_mekari_qontak.webp",
  klikpajak:
    "https://klikpajak.id/wp-content/uploads/2024/07/Mekari-Klikpajak-editorial-logo.png",
};

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "_icon",
    style: _normalizeStyle({ height: props.size, width: props.size })
  }, [
    _createElementVNode("img", {
      src: fileMap[props.product],
      alt: "props.product"
    }, null, 8, _hoisted_1)
  ], 4))
}
}

})