import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "_progress-bar" }
const _hoisted_2 = { class: "_loading" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressBar',
  props: {
    progress: {}
  },
  setup(__props: any) {

const props = __props;

const color =
  props.progress < 50 ? "_green" : props.progress < 99 ? "_yellow" : "_red";

const width = props.progress + "%";

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(Math.floor(props.progress)) + "%", 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(_unref(color)),
        style: _normalizeStyle({ width: width })
      }, null, 6)
    ])
  ]))
}
}

})