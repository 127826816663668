import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "_table-pagination" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

import { nextTick, onUpdated, ref, watch } from "vue";

export type Events = {
  "change-size": [size: number];
  next: [page: number];
  prev: [page: number];
  page: [page: number];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TableSection',
  props: {
    height: {},
    totalData: {},
    isLoading: { type: Boolean }
  },
  emits: ["change-size", "next", "prev", "page"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const size = ref<number>(10);
const page = ref<number>(1);
const body = ref<HTMLElement>();
const totalDataSlot = ref<number>(0);
const totalPage = ref<number>(Math.ceil(props.totalData / size.value));

const pageSizeOptions = [10, 25, 50, 100];

const navigate = (direction: "prev" | "next") => {
  if (direction === "next") {
    emit("next", page.value + 1);
    page.value += 1;
  } else {
    emit("prev", page.value - 1);
    page.value -= 1;
  }
};

const refresh = () => {
  totalPage.value = Math.ceil(props.totalData / size.value);
  totalDataSlot.value =
    body.value?.getElementsByTagName("tr").length ?? size.value;
};

onUpdated(async () => {
  refresh();
});

watch(size, () => {
  emit("change-size", size.value);
});
watch(page, () => {
  emit("page", page.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: "_table",
    style: _normalizeStyle({ height: _ctx.height })
  }, [
    (props.totalData == 0)
      ? _renderSlot(_ctx.$slots, "error", { key: 0 })
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _renderSlot(_ctx.$slots, "header")
              ])
            ]),
            _createElementVNode("tbody", {
              ref_key: "body",
              ref: body
            }, [
              _renderSlot(_ctx.$slots, "body")
            ], 512)
          ]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _cache[4] || (_cache[4] = _createElementVNode("p", null, "Rows per page", -1)),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((size).value = $event))
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(pageSizeOptions, (opt) => {
                  return _createElementVNode("option", {
                    key: opt,
                    value: opt
                  }, _toDisplayString(opt), 9, _hoisted_2)
                }), 64))
              ], 512), [
                [_vModelSelect, size.value]
              ]),
              _createElementVNode("p", null, " Showing " + _toDisplayString((page.value - 1) * size.value + 1) + "-" + _toDisplayString((page.value - 1) * size.value + totalDataSlot.value) + " of " + _toDisplayString(props.totalData), 1)
            ]),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((page).value = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(totalPage.value, (i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: i
                  }, _toDisplayString(i), 9, _hoisted_3))
                }), 128))
              ], 512), [
                [_vModelSelect, page.value]
              ]),
              _createElementVNode("p", null, "of " + _toDisplayString(totalPage.value) + " page", 1),
              _createElementVNode("button", {
                disabled: page.value == 1,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (navigate('prev')))
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("i", { class: "fa-solid fa-chevron-left" }, null, -1)
              ]), 8, _hoisted_4),
              _createElementVNode("button", {
                disabled: page.value == totalPage.value,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (navigate('next')))
              }, _cache[6] || (_cache[6] = [
                _createElementVNode("i", { class: "fa-solid fa-chevron-right" }, null, -1)
              ]), 8, _hoisted_5)
            ])
          ])
        ], 64))
  ], 4))
}
}

})